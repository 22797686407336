import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import PageTitle from '../components/PageTitle'
import Container from '../components/Container'
import Layout from '../components/Layout'
import config from '../utils/siteConfig'

const Text = styled.p`
  text-align: left;
  line-height: 1.6;
  a {
    color: #121212;
  }
`

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title lang={config.language}>
        Política de Privacidade - Cashback Ranking
      </title>

      <link rel="canonical" href="https://www.cashbackranking.com.br/privacy-policy/" />
      <meta charset="utf-8"></meta>
      <meta
        name="description"
        content="Política de Privacidade - Cashback Ranking"
      />
      <meta httpEquiv="content-language" content="pt-br" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1"></meta>
      <meta name="HandheldFriendly" content="True"></meta>
      <meta name="MobileOptimized" content="320"></meta>
    </Helmet>

    <Container>
      <PageTitle>Política de Privacidade</PageTitle>
      <Text>
        A sua privacidade é importante para nós. É política do Cashback Ranking
        respeitar a sua privacidade em relação a qualquer informação sua que
        possamos coletar no site Cashback Ranking, e outros sites que possuímos
        e operamos.
      </Text>
      <br />
      <Text>
        No Cashback Ranking desejamos oferecer a melhor experiência possível
        para que você compare cashbacks de uma mesma loja ao longo do tempo.
        Para isso, não colhemos nenhum dado pessoal seu. Utilizamos apenas
        cookies anônimos para que sua navegação pela plataforma seja única.
      </Text>
      <br />
      <Text>
        O nosso site pode ter links para sites externos que não são operados por
        nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas
        desses sites e não podemos aceitar responsabilidade por suas respectivas
        políticas de privacidade.
      </Text>
      <br />
      <Text>
        O Cashback Ranking atualiza periodicamente todos os valores de cashbacks
        das lojas anunciantes. Então, caso você tenha achado um preço diferente,
        com certeza esse preço foi atualizado pela loja durante esse período de
        atualização, nesses casos o preço válido é sempre o da loja anunciante.
        Para que você possa aproveitar as ofertas e comprar pelo menor preço
        sugerimos cadastrar um alerta de preços disponível em nossa extensão
      </Text>
      <br />
    </Container>
  </Layout>
)

export default NotFoundPage
