import React from 'react'
import styled from 'styled-components'

const Title = styled.h1`
  font-size: ${(props) => (props.small ? '1.5em' : '2em')};
  text-transform: none;
  font-weight: 600;
  text-align: center;
  margin: ${(props) => (props.small ? '1rem 0 4rem 0' : '0 0 1rem 0')};
  line-height: 1.2;
  width: 100%;
  span {
    margin: 0 0 0 0.25em;
  }
  a {
    transition: all 0.2s;
    color: ${(props) => props.theme.colors.base};
    &:hover {
      color: ${(props) => props.theme.colors.highlight};
    }
  }

  @media only screen and (max-width: 480px) {
    font-size: 1.5em;
  }
`

const PageTitle = (props) => {
  return <Title small={props.small}>{props.children}</Title>
}

export default PageTitle
